<template>
  <div class="tagHome">
    <!-- 内容区域 -->
    <el-row>
      <!-- 文章列表 -->
      <el-col class="article" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="article_top">
          <div class="article_top_left">全部文章</div>
          <div class="article_top_right">
            <span>列表形式</span>
            <el-switch v-model="autoFlag" />
          </div>
        </div>
        <!-- 列表 -->
        <div class="clearfix1">
          <div
            v-for="item in posts"
            :key="item.id"
            :style="{
              padding: '10px 0px',
            }"
          >
            <ArticleSmall
              v-seen="{ type: 'animate', class: 'animate__fadeInUp' }"
              :imgWidth="flexScreen != 'mob' ? '200px' : '100px'"
              :imgHeight="flexScreen != 'mob' ? '120px' : '60px'"
              :titleSize="flexScreen != 'mob' ? '20px' : '16px'"
              v-show="autoFlag"
              class="clearfix"
              :article="item"
            >
              <template v-if="flexScreen != 'mob'" #description>
                <div class="description">
                  {{ item.shortContent }}
                </div>
              </template>
              <template #btn>
                <el-button
                  class="ArticleSmall_btn"
                  type="text"
                  size="small"
                  :icon="Right"
                  @click="toPosts({ id: item.id })"
                >
                </el-button>
              </template>
            </ArticleSmall>
            <!-- 大列表 -->
            <ArticleList v-show="!autoFlag" class="clearfix" :article="item">
            </ArticleList>
          </div>
        </div>
        <!-- 空列表 -->
        <el-empty
          class="empty"
          v-if="posts.length == 0"
          description=" "
          :image-size="200"
        >
          EMPTY DATA
        </el-empty>
        <!-- 加载更多 -->
        <div
          class="loadMoreTag"
          v-show="moreFlag && scrollFlag && posts.length > 0"
        >
          <el-button
            size="small"
            type="default"
            @click="getMore(tagId)"
            :loading="moreLoading"
          >
            加载更多
          </el-button>
        </div>
        <div class="without" v-show="!moreFlag">-- 已经到底了 --</div>
      </el-col>
      <!-- 组件区 -->
      <el-col class="components" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <HotTools
          :articleList="componentData.HotsPosts"
          :replyList="componentData.NewReply"
        ></HotTools>
        <!-- 标签区 -->
        <tags :tagsList="tagsList"> </tags>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Right } from "@element-plus/icons";

import { ref, reactive, onMounted, watch, onActivated, computed } from "vue";
import HotTools from "@/pages/home/components/hotTools.vue";
import { useRoute, useRouter } from "vue-router";
import ArticleSmall from "@/components/articleList/articleSmall";

import ArticleList from "../../components/articleList/articleList.vue";
import tags from "../../components/tags/tags.vue";
import usePosts from "../home/usePosts.js";
import useTags from "../home/useTags.js";
import useScroll from "../home/useScroll.js";
import useLoading from "../../hooks/useLoading.js";
import useScreen from "@/hooks/useScreen.js";
import { useHead } from "@vueuse/head";

export default {
  name: "home",
  components: {
    ArticleList,
    HotTools,
    ArticleSmall,
    tags,
  },
  setup(props, context) {
    let backTop = ref(false); //是否返回顶部
    let loading = useLoading(); //动画
    useScroll(loading); //记录滚动条hook
    let store = useStore();
    store.commit("headInfo/UP_ACTIVE_LINK", "");
    let { params } = useRoute();
    let route = useRoute();
    let router = useRouter();


    let posts = usePosts(params.id); //关于文章列表的hook
    let { tagsList } = useTags(); //关于标签hook
    let { flexScreen } = useScreen();


    // 监听路由
    watch(
      () => route.path,
      (newValue, oldValue) => {
        let newFlag = newValue.indexOf("tag") != -1;
        let oldFlag = oldValue.indexOf("tag") != -1;
        //  本页路由之间跳转
        if (newFlag && oldFlag) {
          backTop.value = true;
        }
        // 别的路由跳转
        else {
          // 从首页跳转
          if (oldValue == "/index") {
            backTop.value = true;
          }
          // 文章页跳转
          else {
            backTop.value = false;
          }
        }
      }
    );

    // 去文章详情页
    let toPosts = (params) => {
      router.push({
        name: "posts",
        params: { ...params },
      });
    };
    onActivated(() => {
      store.commit("headInfo/UP_ACTIVE_LINK", "");
      if (backTop.value) {
        window.scrollTo(0, 0);
      }
      useHead({
        // Can be static or computed
        title: computed(
          () =>
            "文章标签-" +
            (tagsList.value.filter((item) => item.id == params.id)[0]?.name ||
              "")
        ),
        meta: [
          {
            name: `description`,
            content:
              "Baymax是一个前端踩坑记录、日常生活分享、技术分享、博客搬运的基于vue3.x的个人博客",
          },
          {
            name: `keywords`,
            content:
              `个人博客，` +
              computed(
                () =>
                  tagsList.value.filter((item) => item.id == params.id)[0]?.name
              ),
          },
        ],
      });
    });

    return {
      toPosts,
      Right,
      flexScreen,
      tagId: params.id,
      ...posts,
      tagsList,
    };
  },
};
</script>

<style scoped lang="scss">
.description {
  height: 70px;
  background-color: var(--white_divBgc) !important;
  color: var(--white_divFc) !important;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}
.ArticleSmall_btn {
  font-size: 18px !important;
  color: var(--white_divFc_light) !important;
}

.ArticleSmall {
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 0;
}
.loadMoreTag {
  margin-top: 5px;
  width: 100%;
  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}

.without {
  padding: 10px 0;
  color: var(--white_divFc);
  font-size: 14px;
}

.article_top_left {
  flex: 1;
  color: var(--white_divFc);
  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.article_top_right {
  flex: 1;
  color: var(--white_divFc);
  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.article_top_right span {
  margin-right: 5px;
}

.article_top {
  width: 100%;
  display: flex;
}

.article {
  height: auto;
  /* border: 1px solid #999; */
}

.components {
  height: auto;
  /* border: 1px solid #999; */
  border-left: none;
}

.tagHome {
  width: 55%;
  margin: 0 auto;
  height: auto;
  border-top: none;
  padding: 20px 40px;
  min-width: 600px;
  position: relative;
  z-index: 1;
  color: var(--white_divFc);
}

.empty {
  background-color: var(--white_divBgc);
  height: 90%;
  margin-top: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .tagHome {
    width: 90%;
    margin: 0 auto;
    height: auto;
    border-top: none;
    padding: 10px;
    min-width: 200px;
    color: var(--white_divFc);
  }
}
</style>
